<template>
    <div>
        <h1>Office Insurance</h1>
    </div>
</template>

<script>
    export default {
        name: 'OfficeInsurance'
    };
</script>
